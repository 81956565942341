const stgConfig = {
  anomalyDetectionService: {
    baseUrl: 'https://solar-flares-cf-m46t4v3h5q-uc.a.run.app/solarflares',
    apiKey: 'AIzaSyA6cPg4evOZTEjjxGTVdrUq0E_-TkAjb44',
  },
  dataPlatform: {
    root: 'stage-data-platform.appspot.com',
    graphQLUri: 'https://stage-data-platform.appspot.com/noc/graphql',
    versionUri: 'https://stage-data-platform.appspot.com/versions',
  },
  hybridAuth: {
    issuer: 'ausm1ww9demaoLzNk0x7',
    url: 'https://gateway.sunrun.com/majstg-hybrid-auth/hybridTokens',
  },
  maps: {
    apiKey: 'AIzaSyDQDw20FW_8iu9GP4S8SOH2fQVjJi2bUWA',
  },
  mixpanelKey: 'ebdec5c54d7f0fbfa874b8cede94d5f5',
  oktaConfigUrl: 'https://majstg-noc.sunrun.com/static/majstgOktaConfig.json',
  oktaEnvironment: 'MajStg',
  performanceApiBaseUrl: 'https://gateway.sunrun.com/majstg-performance-api',
  rollbar: {
    accessToken: 'f3744f87415b4179a0b1a73d35398b81',
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: true,
    payload: {
      environment: 'stage',
      client: {
        javascript: {
          source_map_enabled: true,
        },
      },
    },
    transform(payload) {
      const { trace } = payload.body;
      const locRegex = /^(https?):\/\/[a-zA-Z0-9._-]+\.(majstg-noc.sunrun)\.com(.*)/;
      if (trace && trace.frames) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < trace.frames.length; i++) {
          const { filename } = trace.frames[i];
          if (filename) {
            const m = filename.match(locRegex);
            // changed m[1] => m[3] because (majstg-noc.sunrun) creates a new match group
            trace.frames[i].filename = `${m[1]}://dynamichost${m[3]}`;
            // m[1] = (https?), m[2] = (majstg-noc.sunrun), m[3] = bundle name (everything after .com)
            // example result: https://dynamichost/bundle.app.8as232as.js
          }
        }
      }
    },
  },
};

export default stgConfig;
